import { Component, ElementRef, Input, ViewChild, Inject } from '@angular/core';
import { DragRegionConfig, ScorableDragRegion } from './draggable-helpers/ScorableDragRegion';
import { DropRegionConfig, ScorableDropRegion } from './draggable-helpers/ScorabelDropRegion';
import { IScorable, ScorableData } from '../kh-manip-scorable/IScorable';
import { OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { ScorableDragGroup } from './draggable-helpers/ScorableDragGroup';
import { BehaviorSubject } from 'rxjs';
import { ManipFrame, applyFrameToElement } from '../../../../utils/utils';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../../services/IQuestionEvents';
import gsap from 'gsap/all';

@Component({
  selector: "kh-manipulative-draggable",
  templateUrl: 'kh-manip-scorable-draggable.html'
  })
export class KhManipScorableDraggable implements IScorable, OnDynamicMount {
  @ViewChild('mainElement') public mainElement!: ElementRef<HTMLImageElement>;
  @Input() public src = '';
  @Input() public frame = '0 0 100 100';
  @Input('is-single-mode') public isSingleMode = 'true';
  @Input('reuse-objects') public reuseObjects = 'false';
  @Input() public reportMode = false;
  @Input() public wiggle = false;
  @Input('return-unregistered') public returnUnregistered = 'false';
  @Input() public svgOnly = false;
  @Input() public value = '';
  @Input() public sampleAnswer = '';
  @Input() public key = '';
  @Input() public onChangeCallback: ((data: ScorableData) => void) | undefined;
  public onDoneLoad: (() => void) | undefined;

  private dropRegions: ScorableDropRegion[] = [];
  private dragRegions: ScorableDragRegion[] = [];
  private dragGroup: ScorableDragGroup | null = null;
  private frameData: ManipFrame | null = null;
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  private isLoading$ = this.isLoadingSubject.asObservable();
  private dragHasBeenSetDuringLoad = false;

  public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) protected readonly questionEventsService: IQuestionEventsService) {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }

  public onDynamicMount(data: OnDynamicData): void {
    if (this.mainElement && this.mainElement.nativeElement) {
      this.frameData = applyFrameToElement(this.mainElement.nativeElement, this.frame);
    }

    this.parseChildComponents();
    this.setupWiggle();
  }

  public getManipulativeSize(): ManipFrame {
    const defaultFrame = {x: 0, y: 0, width: 100, height: 100};
    const newFrame = {...defaultFrame, ...this.frameData};
    const dragFrame = this.dragGroup?.getFrame();

    if (dragFrame) {
      newFrame.height = dragFrame.height + dragFrame.y;
      newFrame.width = Math.max(newFrame.width, dragFrame.width);
    }

    return newFrame;
  }

  public getScorableData(): ScorableData {
    const answerData: {[key: string]: string} = {};
    const stateData: {[key: string]: string} = {};

    for (const dropRegion of this.dropRegions) {
      const keyValuePair = dropRegion.getValueKeypair();
      const val = keyValuePair.value ? keyValuePair.value : '';

      stateData[keyValuePair.key] = val;

      if(dropRegion.config.ignoreinscore?.toString() != "true") {
        answerData[keyValuePair.key] = val;
      }
    }

    // set state data
    for (const dragRegion of this.dragRegions) {
      const position = dragRegion.getPosition();

      stateData[dragRegion.config.key] = JSON.stringify(position);
    }
    return {answer: answerData, state: stateData}
  }

  public setScorableData(data: ScorableData, isReportMode = false): void {
    // if setScorableData is called before we're done loading we subscribe to the isLoading$ observable and try again when done
    if (this.isLoadingSubject.value) {
      const sub = this.isLoading$.subscribe(value => {
        if (!value) {
          this.setScorableData(data, isReportMode);
          sub.unsubscribe();
        }
      });

      return;
    }

    // position the drag regions (only student answers will run this really)
    for (const dragRegion of this.dragRegions) {
      if (isReportMode) {
        dragRegion.setCanDrag(false);
      }

      const position = data.state[dragRegion.config.key];

      if (position) {
        this.dragHasBeenSetDuringLoad = true;
        const pos = JSON.parse(position);
        dragRegion.updateAnchorPosition(pos.x, pos.y, isReportMode);
      }
    }

    // Let's call this a heuristic - if no data was found during load we're probably trying to render state data that's just an answer
    // this won't have any positional info so we need to infer fake positions from it
    // In this case the keys in the answer state will be the drop regions, not the drag regions
    if (!this.dragHasBeenSetDuringLoad && isReportMode) {
      for (const dropRegion of this.dropRegions) {
        const dropRegionKey = dropRegion.config.key;
        const dragRegionKey = data.state[dropRegionKey];

        const dragRegion = this.dragRegions.find(d => d.config.key === dragRegionKey);

        if (dragRegion) {
          const pos = dropRegion.getTopLeftPosition();
          dragRegion.updateAnchorPosition(pos.x, pos.y, isReportMode);
        }
        
      }
    }
  }

  private parseChildComponents(): void {
    const childContents = this.mainElement.nativeElement.children;
    this.dropRegions = this.makeDropRegions(childContents);
    this.makeDragRegions(childContents, this.dropRegions).then(dragRegions => {
      this.dragRegions = dragRegions;
      this.isLoadingSubject.next(false);
      if (this.onDoneLoad) {
        this.onDoneLoad();
      }
    });
  }

  private makeDropRegions(childContents: HTMLCollection): ScorableDropRegion[] {
    const dropRegions: ScorableDropRegion[] = [];

    for(let i = 0; i < childContents.length; i++) {
      const child = childContents[i];

      if (child.tagName === 'DROP-REGION') {
        const config = this.processAttributes<DropRegionConfig>(child);
        config.issinglemode = this.isSingleMode !== 'false';
        const dr = new ScorableDropRegion(config);
        dr.onChangeCallback = this.onRegionChanged.bind(this);
        dropRegions.push(dr);
      }
    }

    return dropRegions;
  }

  private async makeDragRegions(childContents: HTMLCollection, dropRegions: ScorableDropRegion[]): Promise<ScorableDragRegion[]> {
    let dragRegions: ScorableDragRegion[] = [];
    const imageLoadPromises: Promise<void>[] = [];

    for(let i = 0; i < childContents.length; i++) {
      const child = childContents[i];

      if (child.tagName === 'DRAG-OBJECT') {
        const config = this.processAttributes<DragRegionConfig>(child);
        config.returnunregistered = this.returnUnregistered === 'true';
        config.dropRegions = dropRegions;
        config.makeclones = this.reuseObjects === 'true';
        config.createIndex = i;

        if ((config as any).src) {
          const img = new Image();
          img.src = (config as any).src;
          imageLoadPromises.push(new Promise(resolve => {
            img.onload = () => {
              config.visualElement = img;

              dragRegions.push(new ScorableDragRegion(config));
              resolve();
            }
          }));
        }
      }
    }

    await Promise.all(imageLoadPromises);

    // ensure the regions are rendered in the order of the markup. The async image load messes up the order.
    dragRegions = dragRegions.sort((a, b) => a.config.createIndex - b.config.createIndex);
    
    console.log(this.mainElement.nativeElement);

    this.dragGroup = new ScorableDragGroup(this.mainElement.nativeElement,
      {
        x: (this.frameData?.width || 0)/2,
        y: this.frameData?.height || 0
      }, this.frameData?.width || 100, dragRegions);

    return dragRegions;
  }

  private onRegionChanged(): void {
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  private processAttributes<T>(child: Element): T {
    const attributes = child.attributes;
    const config: any = {
      parent: this.mainElement.nativeElement
    }

    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      const name = attribute.name.toLowerCase().replace(/-/g, ''); // replace all hyphens
      config[name] = attribute.value;
    }

    return config as T;
  }

  private setupWiggle(): void {
    if (!this.reportMode && this.wiggle ) {
      this.mainElement.nativeElement.addEventListener('click', this.wiggleAnimation.bind(this));
    }
  }

  private wiggleAnimation(e: MouseEvent): void {
    const timelines: gsap.core.Timeline[] = [];

    const killTimelines = () => {
      for (let i = 0; i < timelines.length; i++) {
        timelines[i].progress(1).kill();
      }

      for (const dragRegion of this.dragRegions) {
        if (dragRegion.readyClone) {
          dragRegion.readyClone.dragElement.removeEventListener('mousedown', killTimelines);
        } else {
          dragRegion.dragElement.removeEventListener('mousedown', killTimelines);
        }
      }
    };

    for (const dragRegion of this.dragRegions) {
      const direction = Math.random() > 0.5 ? 1 : -1;
      timelines.push(dragRegion.wiggleAnimation(direction));

      if (dragRegion.readyClone) {
        dragRegion.readyClone.dragElement.addEventListener('mousedown', killTimelines);
      } else {
        dragRegion.dragElement.addEventListener('mousedown', killTimelines);
      }
    }
  }
    
}
