import { Component, Input, Inject } from '@angular/core';
import { IScorable, ScorableData } from '../kh-manip-scorable/IScorable';
import { DynamicContentChild, OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';
import { KhManipulativeWindowComponent } from '../kh-manip-scorable-window/kh-manip-scorable-window.component';
import { KhManipulativeWindowControlsComponent } from '../kh-manip-scorable-window-controls/kh-manip-scorable-window-controls.component';
import { IQuestionEventsService, QUESTION_EVENTS_SERVICE_TOKEN } from '../../../../services/IQuestionEvents';
import { forEach } from 'lodash-es';
import { KhWindowsCounter } from '../kh-manip-windows-counter/kh-manip-windows-counter.component';

@Component({
  selector: 'kh-manipulative-windows',
  templateUrl: 'kh-manip-scorable-windows.component.html',
  styleUrls: ['kh-manip-scorable-windows.component.less'],
})
export class KhManipulativeWindowsComponent implements IScorable, OnDynamicMount {
  @Input() public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @Input('frame') public frame = '0 0 100 100';
  @Input('background-image') public backgroundImage: string = '';

  public box = { left: 0, top: 0, width: 0, height: 0, };

  private windows: KhManipulativeWindowComponent[] = [];
  private controls: KhManipulativeWindowControlsComponent[] = [];
  private counters: KhWindowsCounter[] = [];
  private wasMounted: boolean = false;
  private isReportMode: boolean = false;

  public ngOnInit() {
    const [left, top, width, height] = this.frame.split(' ').map(Number);
    this.box = { left, top, width, height };
  }

  public constructor(@Inject(QUESTION_EVENTS_SERVICE_TOKEN) readonly questionEventsService: IQuestionEventsService) {
    this.questionEventsService.hideInputOnlyForManip.next(true);
  }

  public onDynamicMount(data: OnDynamicData): void {
    this.wasMounted = true;
    const windowsContentChildren: DynamicContentChild[] = data.contentChildren ?? [];

    windowsContentChildren.forEach((c, _index) => {
      const curComponent = c.componentRef.instance;

      if (curComponent instanceof KhManipulativeWindowComponent) {
        this.windows.push(curComponent);
      } else if (curComponent instanceof KhManipulativeWindowControlsComponent) {
        this.controls.push(curComponent);
      } else if (curComponent instanceof KhWindowsCounter) {
        this.counters.push(curComponent);
      }
    });


    // hookup controls with windows
    this.controls.forEach((control) => {
      const windows = this.getWindowsControlledBy(control.key);
      const counters = this.getCountersControlledBy(control.key);

      if (windows) {
        if(counters) {
          counters.forEach((counter) => {
            counter.addWindows(windows);
          });
        }

        control.onForwardClick = () => {
          if (!this.isReportMode) {
            windows.forEach((window) => {window.showNextPane();});
            counters?.forEach((counter) => {counter.onChange(); });
            this.doChangeCallback();
          }
        };

        control.onBackwardClick = () => {
          if (!this.isReportMode) {
            windows.forEach((window) => {window.showPrevPane();});
            counters?.forEach((counter) => {counter.onChange();});
            this.doChangeCallback();
          }
        };
      }
    });
  }

  private doChangeCallback(): void {
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  private getWindowsControlledBy(key: string): KhManipulativeWindowComponent[] | undefined {
    return this.windows.filter((window) => window.controllers.split(',').includes(key));
  }

  private getCountersControlledBy(key: string): KhWindowsCounter[] | undefined {
    return this.counters.filter((counter) => counter.controllers.split(',').includes(key));
  }

  private getWindowByKey(key: string): KhManipulativeWindowComponent | undefined {
    return this.windows.find((window) => window.key === key);
  }

  public onClickChangeValue(val: any): void {
    if (this.onChangeCallback) {
      this.onChangeCallback(this.getScorableData());
    }
  }

  public getScorableData(): ScorableData {
    const answerData: { [key: string]: string } = {};
    const stateData: { [key: string]: string } = {};

    this.windows.forEach((window) => {
      const kvp = window.getCurKeypair();
      answerData[kvp.key] = kvp.value;
      stateData[kvp.key] = kvp.value;
    });

    const data: ScorableData = {
      answer: answerData,
      state: stateData
    };

    return data;
  }

  public setScorableData(data: ScorableData, isRportMode = false): void {
    const stateKeys = Object.keys(data.state);
    this.isReportMode = isRportMode;

    forEach(stateKeys, (key) => {
      const curVal = data.state[key];
      this.getWindowByKey(key)?.setPaneKey(curVal);
    });
  }
}
