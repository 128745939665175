import { KHPoint } from '../../../../../LearningWorlds/pixi-puzzle-framework/PuzzleEngine/KHPoint';
import { ManipFrame } from '../../../../../utils/utils';
import { ScorableDragRegion } from './ScorableDragRegion';

type rowLayoutData = {
  draggables: ScorableDragRegion[];
  totalWidth: number;
  totalHeight: number;
  yPos: number;
}

/**
 * While this is a "group" it isn't an actual DIV element or anything - it just positions the draggables with some logic.
 */
export class ScorableDragGroup {
  private draggables: ScorableDragRegion[];
  private framePosition: ManipFrame;
  private draggablePadding = 15;
  private maxWidth;
  private topCenterPosition: KHPoint;
  private draggableRows: rowLayoutData[] = [];
  private scaleFactor: number;

  public constructor(parent: HTMLElement, topCenterPosition: KHPoint, maxWidth: number, draggables: ScorableDragRegion[], scaleFactor = 1) {
    this.draggables = draggables;
    this.maxWidth = maxWidth;
    this.topCenterPosition = topCenterPosition;
    this.scaleFactor = scaleFactor;

    this.positionDraggables();
  }

  public getFrame(): ManipFrame {
    return this.framePosition;
  }

  private positionDraggables() {
    let curRowLayoutData: rowLayoutData = { draggables: [], totalWidth: 0, totalHeight: 0, yPos: this.draggablePadding };

    for (let i = 0; i < this.draggables.length; i++) {
      const draggable = this.draggables[i];
      const rect = draggable.getUnscaledSize();

      let newWidth = curRowLayoutData.totalWidth + rect.width + this.draggablePadding;
      
      if (newWidth > this.maxWidth) {
        this.draggableRows.push({...curRowLayoutData});
        curRowLayoutData = { draggables: [], totalWidth: 0, totalHeight: 0, yPos: curRowLayoutData.yPos + curRowLayoutData.totalHeight + this.draggablePadding };
        newWidth = rect.width + this.draggablePadding;
      }

      curRowLayoutData.draggables.push(draggable);
      curRowLayoutData.totalWidth = newWidth;
      curRowLayoutData.totalHeight = Math.max(curRowLayoutData.totalHeight, rect.height);
    }

    if (curRowLayoutData.draggables.length > 0) {
      this.draggableRows.push(curRowLayoutData);
    }

    let totalHeight = 0;

    for (let i = 0; i < this.draggableRows.length; i++) {
      const row = this.draggableRows[i];
      const xPos = (this.maxWidth - row.totalWidth) / 2;
      let currentX = xPos;
      let currentY = row.yPos + this.topCenterPosition.y;

      for (let j = 0; j < row.draggables.length; j++) {
        const draggable = row.draggables[j];
        const rect = draggable.getUnscaledSize();
        draggable.updateAnchorPosition(currentX, currentY);
        currentX += rect.width + this.draggablePadding;
      }

      totalHeight += row.totalHeight + this.draggablePadding;
    }

    this.framePosition = { x: 0, y: this.topCenterPosition.y + this.draggablePadding, width: this.maxWidth, height: totalHeight };
  }
}
