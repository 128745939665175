import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { ScorableData } from "../kh-manip-scorable/IScorable";
import { MANIP_FRAME_IDLE } from "../../../../utils/utils";
@Component({
  selector: "clickable-region",
  templateUrl: "kh-manip-scorable-region.component.html",
  styleUrls: ["kh-manip-scorable-region.component.less"],
})
export class KhManipulativeClickableRegionComponent implements AfterViewChecked {
  public onChangeCallback: undefined | ((data: ScorableData) => void); // callback
  @Input("key") public key: string = "";
  @Input("index") public index!: number;
  @Input("value") public value!: ScorableData;
  @Input("shape") public shape: string = "rectagle";
  @Input("background") public background: string = "";
  @Input("has-outline") public hasOutline: string = "false";
  @Input("frame") public frame = "0 0 0 0";
  @Input("clicked-image") public clickedImage?: string;
  @Input("ignore-in-score") public ignoreInScore = false;
  @Input("default-value") public defaultValue?: string;
  @Input("selected") selected: boolean = false;
  @Input('countname') public countVarName: string = '';
  @ViewChild("region", { static: true })
  public regionElement!: ElementRef<HTMLInputElement>;

  public clickCallback: undefined | ((data: ScorableData, element: KhManipulativeClickableRegionComponent) => void); // callback
  public box = { left: 0, top: 0, width: 0, height: 0 };
  public borderRadius = "";
  public border: string = "";
  private enabled: boolean = true;

  public ngOnInit() {
    this.setBackground();

    this.borderRadius = this.shape?.toLocaleLowerCase() === "oval" ? "50%" : "";
    const [left, top, width, height] = this.frame.split(" ").map(Number);
    this.box = { left, top, width, height };
    this.border = this.hasOutline === "true" ? MANIP_FRAME_IDLE : "none";
  }

  public setClickEnabled(val: boolean) {
    this.enabled = val;

    if (!val) {
      this.regionElement.nativeElement.style.cursor = "none";
    }
  }

  private setBackground() {
    if (this.selected) {
      this.regionElement.nativeElement.style.backgroundImage = `url(${this.clickedImage})`;
    } else {
      this.regionElement.nativeElement.style.backgroundImage = "";
    }
  }

  ngAfterViewChecked(): void {
    this.setBackground();
    this.regionElement.nativeElement.style.cursor = this.enabled ? "pointer" : "default";
  }

  public onChangeValue(val: string): void {
    if (this.enabled) {
      this.selected = !this.selected;
      this.setBackground();
  
      if (this.clickCallback) {
        this.clickCallback(this.getRegionScorableData(), this);
      }
    }
  }

  public getRegionScorableData() {
    const selected = this.selected ? '1' : '0';

    const data: ScorableData = {
      answer: { [this.key]: selected },
      state: { [this.key]: selected },
    }

    return data;
  }
}
